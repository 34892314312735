import React, {useEffect, useState} from 'react';
import axios from "axios";

import Navbar from "../components/navbarComponents/navbar";
import AboutMe from "../components/homePageComponents/aboutMe";
import ResumeExperiences from "../components/homePageComponents/experiences";
import ResumeEducation from "../components/homePageComponents/education";
import Footer from "../components/templateComponents/footer";
import PersonalExperiences from "../components/homePageComponents/personalExperiences";


function HomePage() {

    const [myName, setMyName] = useState([]);

    useEffect(() => {
        const fetchMyName = async () => {
            try {
                const response = await axios.get('/myName');
                setMyName(response.data);
            } catch (err) {
                if (err.response) {
                    // not a 200 success response
                    console.log(err.response.data);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchMyName();
    }, []);

    console.log(myName);

    return (

        <div>

            <div className="hero-homepage">
                <Navbar/>
                <div className="content">
                    {/*<h1>{myName.msg}</h1>*/}
                    <h1>Alex Kenny</h1>
                    <h2>Software Developer</h2>
                </div>
            </div>

            <div className="resume">
                <h2>My Coding Experiences</h2>
                <ResumeExperiences />
                <h2>My Educational Background</h2>
                <ResumeEducation />
                <h2>My Personal Coding Experiences</h2>
                <PersonalExperiences />
            </div>

            <AboutMe/>
            <Footer />

        </div>

    );

}


export default HomePage;
