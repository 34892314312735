import React, {useEffect, useState} from 'react';
import Navbar from "../components/navbarComponents/navbar";
import Footer from "../components/templateComponents/footer";
import axios from "axios";

function Projects() {

    const [myProjects, setMyProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('/myProjects');
                setMyProjects(response.data);
            } catch (err) {
                if (err.response) {
                    // not a 200 success response
                    console.log(err.response.data);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchProjects();
    }, []);

    console.log(myProjects);

    return (

        <div>

            <div className="hero-projects">
                <Navbar/>
                <div className="content">
                    <h1>My Personal Coding Projects</h1>
                    <h2>Take a look below at what I've done!</h2>
                </div>
            </div>

            <div className="projects">

                <h2>The Projects <i className="fa-solid fa-square-kanban"></i></h2>

                <div className="cardRow">

                    {/* Dynamically allow for projects to be added to this page from a ps-server dashboard */}
                    {(() => {
                        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                            return (
                                <div className="card">
                                    <img src={require('../assets/img/AK-Logo@1x.png')} alt="" className="card-img"/>
                                    <h3>MLB The Show Ranked Season Stats Charted</h3>
                                    <p>{myProjects.projectShortDescription}</p>
                                    <p className="fancyFont">Languages Used: {myProjects.languagesUsed}</p>
                                    <a href="/mlbTheShowRankedStats" rel="noreferrer" className="goToProject">
                                        Ranked Seasons Stats
                                    </a>
                                </div>
                            );
                        } else {
                            return (
                                <div className="card">
                                    <img src={require('../assets/img/AK-Logo@1x.png')} alt="" className="card-img"/>
                                    <h3>Projects Are Currently In Development</h3>
                                    <p>When they are ready to publish, they will be introduced here.</p>
                                    {/*<p className="fancyFont">Languages Used: {props.projectInfo.languagesUsed}</p>*/}
                                    {/*<a href={props.projectInfo.projectUrl} target="_blank" rel="noreferrer" className="goToProject">*/}
                                    {/*    Go To This Project*/}
                                    {/*</a>*/}
                                </div>
                            );
                        }
                    })()}



                </div>

            </div>

            <Footer/>

        </div>

    );

}

export default Projects;