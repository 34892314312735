import React from 'react';

function ResumeEducation() {

    return (

        <div>
            <div className="wrapper">
                <div className="center-line-education"></div>
                <div className="row row-1">
                    <section>
                        <i className="icon fa-solid fa-graduation-cap"></i>
                        <div className="padding-2x">
                            <div className="details">
                                <span className="title">Western Governors University, BS in Computer Science</span>
                                <span className="dates">2022 - Present</span>
                            </div>
                            <p>
                                Will be taking classes at night and after a full day of working.
                            </p>
                        </div>
                    </section>
                </div>
                <div className="row row-2">
                    <section>
                        <i className="icon fa-solid fa-graduation-cap"></i>
                        <div className="padding-2x">
                            <div className="details">
                                <span className="title">Central Washington University, BA in Business Admin <br/> Minor in Web Design</span>
                                <span className="dates">2016 - 2020</span>
                            </div>
                            <p>
                                GPA: 3.35/4 with Honors in 2017/18 and 2020
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>

    );

}

export default ResumeEducation;