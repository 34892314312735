import React from 'react';

function Footer() {

    return (
        <div className="copyright">
            <h3>My Social Media Links</h3>
            <ul className="copyright-social-links">
                <li>
                    <a href="https://www.linkedin.com/in/alex-p-kenny-032543124/" target="_blank" rel="noreferrer">
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="https://github.com/AlexKenny6" target="_blank" rel="noreferrer">
                        <i className="fa-brands fa-github"></i>
                    </a>
                </li>
            </ul>
            <p className="topP">Made With <i className="fa-solid fa-heart"></i> By Alex Kenny</p>
            <p>Copyright <i className="fa-regular fa-copyright"></i> 2022</p>
        </div>
    );

}

export default Footer;