import React from 'react';

function AboutMe() {

    return (

        <div className="about">

            <div className="about-header-content">
                <h2 className="about-h2">
                    About Me
                    <i className="fa-solid fa-face-grin-wide"></i>
                </h2>
                <img className="AK-Img" src={require('../../assets/img/AlexKennyProfessionalPic.png')} alt="AK Img"/>
            </div>

            <p>

                <span role="img" aria-label="U+1F44B">
                    👋🏽 Hi, my name is Alex
                </span>

                <span role="img" aria-label="U+1F393">
                    🎓 Going back to school to get my Bachelor’s of Science in Computer Science.
                    I will be working on this at night while working full time.
                    Graduated in 2020 with a Bachelor's of Science in Business Administration and a Minor in Web Design.
                </span>

                <span role="img" aria-label="U+1F307">
                    🌇 Born and raised in the PNW, with a love for coding and building cool things.
                </span>

                <span role="img" aria-label="U+1F4DA">
                    📚 I have interned as a Website Developer at Arrow Design in Dublin, Ireland.
                    Became a Student Web Developer in the Web Services Department at Central Washington University.
                    Was a Junior Web Developer at Drop-In Gaming.
                    Was a Software Engineer I at Harbor Compliance.
                    Now looking for my next opportunity!
                </span>

                <span role="img" aria-label="U+1F4BB">
                    💻 As I learn more and more about the software development world, I want to continue coding and building awesome things.
                </span>

                <span role="img" aria-label="U+1F3D4">
                    🏔 I love to ski, workout/lift, play baseball, play video games, and watch Star Wars content.
                </span>

                <span role="img" aria-label="U+1F4AA">
                    💪🏽 I am a coach able person who is passionate about website development using languages like PHP, React.js, and SQL. I like a good challenge that makes me think, especially when I think of the solution doing something completely different.
                </span>
            </p>
        </div>

    );


}

export default AboutMe;