import React from 'react';

function PersonalExperiences() {

    return (

        <div>
            <div className="wrapper">
                <div className="center-line-skills"></div>

                <div className="row row-1" id="scroll-icon-link">
                    <section>
                        <i className="icon fa-solid fa-laptop"></i>
                        <div className="padding-2x">
                            <div className="details">
                                <span className="title">Rebuilding My Portfolio Website</span>
                                <span className="dates">September 2022</span>
                            </div>
                            <ul>
                                <li>Integrated GitHub Actions with a test suite for the portfolio site code</li>
                                <li>Created a droplet on Digital Ocean and spun up an instance to house the site</li>
                                <li>Set up a CICD for the droplet</li>
                                <li>Gained experience working on a Ubuntu virtual machine installing programs like Nginx and PM2</li>
                            </ul>
                        </div>
                    </section>
                </div>

                <div className="row row-2">
                    <section>
                        <i className="icon fa-sharp fa-solid fa-laptop-code"></i>
                        <div className="padding-2x">
                            <div className="details">
                                <span className="title">Using Python To Build Stock Price Graphs</span>
                                <span className="dates">December 2018</span>
                            </div>
                            <ul>
                                <li>Built a simple stock price projection project with Anaconda and Jupyter</li>
                                <li>Used the Yahoo Finance API</li>
                                <li>Used NumPy and Pandas</li>
                            </ul>
                        </div>
                    </section>
                </div>

            </div>
        </div>

    );

}

export default PersonalExperiences;