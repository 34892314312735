import React from 'react';

import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";

// pages
import HomePage from "./pages/homepage";
import Projects from "./pages/projects";

// homepage css
import './css/templates/hero.css';
import './css/homePage/resume.css';
import './css/homePage/aboutMe.css';

// projects page css
import './css/projectPage/projects.css';

// navbar css
import './css/templates/navbar.css';

// footer css
import './css/templates/footer.css';

// misc css
import './css/misc/App.css';

function App() {

    return (
        <div>
            <Router>
                <Routes>
                    {/* Portfolio Website Proj Routes */}
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/projects" element={<Projects/>}/>
                </Routes>
            </Router>
        </div>
    );

}


export default App;
