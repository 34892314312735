import React from 'react';

function ResumeExperiences() {

    return (

        <div>
            <div className="wrapper">
                <div className="center-line-experiences"></div>
                <div className="row row-1" id="scroll-icon-link">
                    <section>
                        <i className="icon fa-solid fa-laptop"></i>
                        <div className="padding-2x">
                            <div className="details">
                                <span className="title">Software Engineer I @ Harbor Compliance (Remote)</span>
                                <span className="dates">January 2022 - June 2022</span>
                            </div>
                            <ul>
                                <li>Worked in a PHP/Vue JS/MySQL build</li>
                                <li>Built code for an annual report/information form codebase</li>
                                <li>Built PHPUnit Tests and used Circle CI for CICD</li>
                                <li>Pushed several branches to production and 2400+ lines of code</li>
                                <li>Used Docker and GitHub</li>
                                <li>Collaborated with teammates on ticket estimations and bouncing ideas for how to
                                    approach logic blockers in the code
                                </li>
                                <li>Worked in an agile team and used Jira with close contact/feedback with QA and
                                    Business
                                </li>
                                <li>The codebase was built with CakePHP</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div className="row row-2">
                    <section>
                        <i className="icon fa-solid fa-code-branch"></i>
                        <div className="padding-2x">
                            <div className="details">
                                <span className="title">Junior Web Developer @ Drop-In Gaming LLC (Remote)</span>
                                <span className="dates">October 2020 - November 2021</span>
                            </div>
                            <ul>
                                <li>Worked in a PHP/jQuery/MySQL build</li>
                                <li>Helped build AWS EC2 instances with load balancers and Aurora databases</li>
                                <li>Helped build a web socket</li>
                                <li>Created and maintained code used for a video game tournament/bracket platform</li>
                                <li>Integrated APIs from the following: Twitch, Facebook, and Discord</li>
                                <li>Built and supported a notification system</li>
                                <li>Worked on an agile team</li>
                                <li>Committed 130k lines of code in 671 commits to production</li>
                                <li>The codebase was built on a WordPress site</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div className="row row-1">
                    <section>
                        <i className="icon fa-solid fa-file-code"></i>
                        <div className="padding-2x">
                            <div className="details">
                                <span className="title">Student Web Developer @ Central Washington University Web Services (Ellensburg, WA & Remote)</span>
                                <span className="dates">November 2019 - June 2020</span>
                            </div>
                            <p>
                                Built, designed and supported content on the cwu.edu website
                                in an agile work environment with a team of developers using
                                Drupal, PHP, JS, HTML, and CSS.
                            </p>
                        </div>
                    </section>
                </div>
                <div className="row row-2">
                    <section>
                        <i className="icon fa-sharp fa-solid fa-laptop-code"></i>
                        <div className="padding-2x">
                            <div className="details">
                                <span className="title">Web Design Intern @ Arrow Designs (Dublin, Ireland)</span>
                                <span className="dates">June 2019 - August 2019</span>
                            </div>
                            <p>
                                Created, designed, and developed websites using leading website creation software like
                                Wordpress with PHP, HTML, CSS, and JS with premiere software editors like Visual Studio Code.
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>


    );

}

export default ResumeExperiences;